.vc_general.vc_tta.project-tab{
    .vc_tta-tabs-container{
        margin-bottom: 40px !important;
    }
    li.vc_tta-tab{
        margin: 0 40px;
        width: 30%;
        &.vc_active{
            a{
                background: var(--color-blue);
                color: white;
            }
        }
        a{
            background: var(--color-light);
            color: var(--color-font);
            padding: 0;
            font-family: 'font-m';
            font-size: 20px;
        }
    }
}

.single{
    .head-sec{
        margin-bottom: 40px;
        background-image: url(https://phh-groups.com/wp-content/uploads/2021/03/project-bg.jpg) !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important; 
    }

    .single-thumb{
        display: none;
    }

    article.post{
        margin-bottom: 0;
    }
}

.single-detail{
    margin-bottom: 50px;
    .wpb_single_image{
        margin: auto;
        margin-bottom: 20px;

        img{
            max-width: 150px;
            max-height: 80px;
        }
        &.shadow-img{
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    h2.vc_custom_heading{
        font-size: 33px;
        color: var(--color-blue);
        font-family: 'font-cn-b' !important;
    }

    table{
        border: none;
        td{
            line-height: 20px;
            border: none;
            font-family: 'font-r';
            &:first-child{
                width: 170px;
                font-family: 'font-b';
                padding-left: 0;
            }
        }
    }
}

.post-gallery{
    li.isotope-item{
        position: static !important;
        width: 33.3333333%;
        padding: 5px !important;
        margin: 0 !important;

        img{
            height: 250px;
            object-fit: cover;
            margin: auto;
        }
    }
}