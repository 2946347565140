
.head-sec{
    height: 450px;
    padding-top: 0 !important;

    .head-cover{
        background: rgba(0,0,0,0.4);
        height: 100%;
    }

    h2{
        color: white !important;
        font-family: 'font-cn-b' !important;
        letter-spacing: 2px;
    }
}

.about-sec.sec-2{
    .wpb_text_column{
        color: var(--color-font);
    }

    .about-header{
        p{
            color: var(--color-font);
        }

        p.hohup{
            color: var(--color-blue);
        }
    }

    .service{
        ul{
            a{
                color: var(--color-blue);
            }
        }
    }

    .about-icon-row .wpb_single_image h2{
        color: var(--color-font);
    }
}

.mivi-sec{
    .mivi{
        .mission{
            .vc_column-inner>.wpb_wrapper{
                max-width: 570px;
                margin-left: auto;
                padding: 15px;
            }
        }
        .vision{
            .vc_column-inner>.wpb_wrapper{
                max-width: 570px;
                margin-right: auto;
                padding: 15px;
            }
        }

        p{
            font-size: 18px;
        }

        .wpb_single_image{
            display: flex;
            flex-direction: column;
            margin-bottom: 0;

            h2{
                order:2;
                font-family: 'font-b';
                margin-bottom: 10px;
            }

            img{
                max-width: 80px;
            }
        }
    }
}

.cert-sec{
    margin-top: 50px;
    .custom-header{
        margin-bottom: 50px;
    }
    .cert-gallery{
        ul{
            width: fit-content;
            margin: auto;

            li.isotope-item{
                position: static !important;
                margin: 20px;

                img{
                    max-width: 315px;
                    max-height: 433px;
                    box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
                }
            }
        }
    }
}

.company-policy {
    margin: 100px 0;

    .custom-header{
        margin-bottom: 15px;
    }

    p{
        text-align: justify;
    }
}

.quality-objective{
    margin: 100px 0;



    .wpb_single_image{
        display: flex;
        flex-direction: column;

        .vc_single_image-wrapper{
            border-radius: 50%;
            padding: 5px;
            border: 3px solid #000064;

            img{
                width: 80px;
            }
        }

        h2{
            order:2;
            text-align: center;
            font-family: 'font-m';
            font-size: 16px;
            max-width: 210px;
            margin-left: auto;
            margin-right: auto;
            line-height: 22px;
        }
    }

    

}

.policy-objective{
    margin-bottom: 150px;

    .policy{
        width: fit-content;
        margin: auto;

        li{
            margin-bottom: 15px;
            position: relative;
            list-style: none;

            &:before{
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                background: #cccccc;
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
            }

            strong{
                text-transform: uppercase;
                color: #000064;
                font-family: 'font-m';
            }
        }
    }
    @media only screen and (min-width: 768px){
        .policy .wpb_column.vc_column_container.vc_col-sm-2{
            width: 31%;
            &:first-child,&:nth-child(2){
                width: 32%;
            }
        }
    }
    
}

.safety-health{

    .point{
        font-weight: 700;
    }

    @media only screen and (min-width: 768px){
        max-width: 810px;
        margin: auto !important;
    }
    .left-quote{
        .wpb_wrapper{
            display: flex;

            .vc_separator {
                order: 2;
                margin-left: 50px;
            }

            img{
                width: 60px;
            }
        }
    }
    .right-quote{
        .wpb_wrapper{
            display: flex;

            &>*{
                margin-bottom: 0 !important;
            }

            .vc_separator{
                margin-right: 50px;
            }

            img{
                width: 60px;
            }
        }
    }
}