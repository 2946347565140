@media only screen and (max-width: 1199px){
    .mobile-side {
        z-index: 9 !important;
        background: var(--color-blue);

        .mobile-branding{
            background: white;
        }
    }
}
@media (--desktop){

}

@media (--not-laptop){
    footer#colophon.site-footer{
        .footer-logo-wrap{
            width: 100%;
            text-align: center;
            img{
                max-width: 330px;
            }
        }
        .sidebar-column{
            width: 50%;
            float: left;

            &:last-child{
                width: 100%;

                .footer-icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a{
                        margin: 10px;
                    }
                }
            }
        }
    }
    .head-sec h2{
        font-size: 40px !important;
    }
}

@media (--ipad){
    .custom-header .wpb_wrapper .header-front{
        font-size: 20px;
    }

    .home-slider h3{
        font-size: 50px !important;
        width: 500px !important;
    }

    .home-slider p#slider-1-slide-1-layer-11{
        line-height: 22px !important;
    }

    .project-grid{
        .vc_btn3-container.vc_btn3-inline{
            margin-bottom: 0;
        }
        p{
            -webkit-line-clamp: 2;
        }

        .vc_grid-item-mini .vc_gitem-animate-slideBottom .vc_gitem-zone-b{
            top: 110px;
        }
    }
}
@media (--ipad-mini){
    .project-grid{
        .vc_btn3-container.vc_btn3-inline{
            margin-bottom: 0;
        }
        p{
            -webkit-line-clamp: 2;
        }

        .vc_grid-item-mini .vc_gitem-animate-slideBottom .vc_gitem-zone-b{
            top: 90px;
        }
    }
}

@media (--portable){
        .home-slider {
            h1{
                display: block !important;
            }
            h3{
                font-size: 50px !important;
                width: 500px !important;
            }
        }
        .custom-header .wpb_wrapper .header-back{
            font-size: 32px !important;
        }
        .contact-form{
            display: none;
        }

        .customer-logo .wpb_gallery{
            ul li.isotope-item{
                width: 33.333333%;
            }
        }
        .post-gallery li.isotope-item{
            width: 100%;
        }
        .single-detail table td:first-child{
            width: fit-content;
        }

        .single-detail{
            .wpb_single_image.vc_align_left{
                text-align: center;

                img{
                    max-width: 150px;
                }
            }
            .wpb_single_image.vc_align_left.shadow-img img{
                max-width: 100%;
            }
            h2.vc_custom_heading{
                text-align: center !important;
            }

            .shadow-img{
                margin-bottom: 50px;
            }
        }
        
        .home-slider{
            h1{
                img{
                    transform: translate(0%,-34%);
                    width: 20px;
                }
            }
        }
}

@media (--not-portable){
    .contact-form2{
        display: none;
    }
}

@media (--phone){

    .project-grid .vc_custom_heading h2 {
        font-size: 18px !important;
    }

    body, body p{
        font-size: 14px;
    }

    .project-grid .vc_grid-item-mini .vc_gitem-animate-slideBottom .vc_gitem-zone-b {
        top: 170px;
    }
    .custom-header .wpb_wrapper {
        .header-back{
            font-size: 20px !important;
        }
        .header-front{
            font-size: 20px !important;

            p{
                font-size: 20px !important;
            }
        }

    }

    .about-header p{
        font-size: 20px;
        line-height: 32px;
    }

    .about-sec .wpb_text_column{
        font-size: 14px;
    }

    .custom-btn .vc_btn3.vc_btn3-color-grey.vc_btn3-style-outline{
        font-size: 12px;
        padding: 10px 10px 5px;
        margin: 10px;
    }
    .client-sec .client-slider img{
        max-width: 100px !important;
    }
    .about-sec .about-icon-row .wpb_single_image h2{
        font-size: 18px !important;
    }
    .about-sec .about-icon-row .wpb_single_image img{
        width: 80px ;
    }

    .contact-sec .custom-header .wpb_wrapper .header-back, .contact-sec .custom-header .wpb_wrapper .header-front{
        line-height: 32px;
    }
    .contact-form2 input[type=email], .contact-form2 input[type=tel], .contact-form2 input[type=text], .contact-form2 textarea, .contact-form input[type=email], .contact-form input[type=tel], .contact-form input[type=text], .contact-form textarea{
        font-size: 14px;
        padding: 10px;
    }
    .contact-form2 .action input, .contact-form .action input{
        font-size: 14px;
    }
    footer#colophon.site-footer #sidebar-footer table td,
    footer#colophon.site-footer #sidebar-footer .textwidget p{
        font-size: 14px;
    }

    .home-slider h3{
        font-size: 35px !important;
        max-width: 350px !important;
        margin-top: 10px !important;
    }

    .home-slider p#slider-1-slide-1-layer-11{
        line-height: 18px !important;
        font-size: 12px !important;
    }

    footer#colophon.site-footer{
        .sidebar-column{
            width: 270px;
            float: none;
            margin: auto;

            &:last-child{
                width: 100%;
            }
        }
        .footer-logo-wrap img{
            padding-left: 0;
        }
        #sidebar-footer table td:first-child{
            padding-left: 0;
        }
    }

    .contact-sec{
        .custom-header .wpb_wrapper{
            display: block;

            .header-front,.header-back{
                margin: 0;
                text-align: center !important;
            }
        }
    }

    .about-sec{
        .about-header{
            line-height: 32px;
            p.hohup{
                font-size: 20px;

                img{
                    max-width: 20px;
                    top: -2px;
                }
            }
        }
        
    }

    .header-btn-row{
        .custom-btn.line{
            text-align: center;

            &:before{
                content: none;
            }
        }
        .custom-header .wpb_wrapper{
            justify-content: center;
        }
    }

    .home-slider h1 img{
        transform: translateY(-50%);
    }

    .shadow-img.main-img figure img, .shadow-img.main-img .shadow-img.sub-img img{
        height: 250px;
    }
    .shadow-img.main-img{
        margin: auto;
        margin-bottom: 70px;
    }

    .mivi-sec .mivi p {
        font-size: 18px;
        font-size: 14px;
    }

    .mivi-sec .mivi .wpb_single_image h2{
        font-size: 18px;
    }

    .head-sec{
        height: 300px;
    }
    .project-grid{
        .vc_grid-container.vc_clearfix.wpb_content_element.vc_basic_grid{
            max-width: 300px;
            margin: auto;
            margin-bottom: 50px;
        }
    }

    .vc_tta-panel{
        .vc_tta-panel-heading{
            background: var(--color-grey) !important;
        }

        &.vc_active{
            .vc_tta-panel-heading{
                background: var(--color-blue) !important;
            }
            

            a{
            color: white !important;
            }
        }
    }

    .single-detail table td{
        font-size: 14px;
    }

    .service-sec {
        text-align: center;
        h3{
            text-align: center !important;
        }
        .wpb_single_image{
            margin-bottom: 0;
            text-align: center !important;
                img.vc_single_image-img{
                max-width: 80px;
            }
        }
        li{
            list-style: none;
        }
    }

    .detail-sec .contact-title p{
        font-size: 16px;
        text-align: center;
    }

    .detail-sec .contact-title h2.vc_custom_heading{
        text-align: center !important;
    }

    .detail-sec .contact-detail .vc_col-sm-4:first-child .vc_column-inner>.wpb_wrapper{
        margin-left: auto;
    }
    .detail-sec .contact-detail .wpb_single_image h2{
        text-align: center;
    }
    .contact-detail figure.wpb_wrapper.vc_figure {
        text-align: center;
    }
    .contact-detail .vc_col-sm-4:nth-child(2) .wpb_text_column.wpb_content_element {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
    }

    .detail-sec .contact-detail .vc_col-sm-4 .vc_column-inner>.wpb_wrapper{
        margin: auto;
        width: 220px;
        text-align: center;

        td{
            font-size: 14px;
            text-align: left;
        }
    }

    .reverse .wpb_column.vc_column_container:first-child{
        order: 2;
    }
    .policy-objective .policy{
        margin: 0;
    }

    
}

