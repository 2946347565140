#header .main-header{
	@media (--desktop) {
		padding: 10px 0;
	}
}
#primary-menu .menu-item{
	&.current-menu-item{

		a{
			color: var(--color-blue);
			font-family: 'font-cn-blk';
			position: relative;

			&:before{
				content: '';
				width: 5px;
				height: 5px;
				position: absolute;
				background: #000064;
				left: 0px;
				left: -10px;
				border-radius: 50%;
				top: 45%;
				transform: translate(-50%, -50%);
			}
		}
	}

	a{
		font-family: 'font-cn-m';
		font-weight: 400;
		color: var(--color-font);
		font-size: 14px;

		&:hover{
			color: var(--color-blue);
			transition: all 0.3s ease-in-out;
		}
		
		@media (--desktop) {
			margin: 0 15px !important;
		}
	}
}

.contact-form,.contact-form2{
	input[type='text'],
	input[type='email'],
	input[type='tel'],
	textarea
	{
		color: var(--color-dark);
		border-radius: 0;
		border: 1px solid var(--color-dark);
		margin-bottom: 15px;
		font-family: 'font-cn-b';
		width: 100%;
		font-size: 16px;
	}

	textarea{
		height: 145px;
	}
 
	.action {
		.col-sm-6:first-child{
			padding-left: 0;
		}
		.col-sm-6:last-child{
			padding-right: 0;
		}
		input {
			width: 100%;
			font-size: 18px;
			border-radius: 0;
			font-size: 18px;
			font-family: 'font-cn-r';
			font-weight: 400;
		}

		input[type='reset']{
			background: var(--color-dark);
		}
		input[type='submit'],input[type='reset']{
			padding: 12px;
		}
	}

}

footer#colophon.site-footer {
	padding-top: 0;
	padding-bottom: 0;
	
	.footer-logo{
		max-width: 500px;
		padding-left: 15px;
	}

	#sidebar-footer{
		margin-top: 40px;
		margin-bottom: 20px;

		.sidebar-column{
			&:first-child, &:nth-child(2){
				@media (--desktop){
					width: 40%;
				}
			}
			&:last-child{
				@media (--desktop){
					width: 20%;
				}
			}

			&:first-child{
				.textwidget{
					text-transform: uppercase;
				}
			}
		}

		.widget-title{
			color: var(--color-font);
			text-transform: lowercase;
			font-family: 'font-m';
			font-size: 18px;
			margin-bottom: 10px;
		}

		.textwidget{
			p{
				color: var(--color-blue);
			}
		}

		table{
			border: none;
			td{
				color: var(--color-blue);
				border: none;
				padding-top: 0;
				padding-bottom: 0;

				&:first-child{
					width: 10px;
					padding-right: 0;
					
				}
			}
		}
		.footer-icon{
			i{
			font-size: 20px;
			/* margin-bottom: 20px; */
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--color-blue);
			color: white;
			border-radius: 50%;
			margin-bottom: 15px;
			}
			br{
				display: none;
			}
		}
	}
	p.copyright {
		margin-bottom: 0;
		text-align: center;
		background: var(--color-dark);
		color: white;
		padding: 10px 0;
		font-size: 12px;
	}
}

img.custom-logo{
	max-height: 50px !important;
}

#site-navigation{
	.sub-menu{
		li{
			background: white;
			border-bottom: 1px solid var(--color-blue);

			&:last-child{
				border: none;
			}
		}
	}
}

.tooltip-box {
	position: relative;
	display: inline-block;
	cursor: pointer;
  }
.tooltip-box .tooltip-text {
	opacity: 0;
	width: 100px;
	/* background-color: #000; */
	color: #fff;
	text-align: center;
	padding: 10px;
	position: absolute;
	z-index: 1;
	box-shadow: 0px 0px 2px rgba(0,0,0,0.18);
	transition: opacity 0.3s ease-in-out;
	top: 0;
	left: 50px
}
.tooltip-box .tooltip-text.show{
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
}

.text-center .wpb_wrapper {
	justify-content: center;
	text-align: center;
}
  

.point{
	li{
		position: relative;
		line-height: 24px;
		margin-bottom: 20px;
		padding-left: 10px;

		&:before{
			content: '';
			width: 20px;
			height: 20px;
			position: absolute;
			background: white;
			left: -25px;
			border-radius: 50%;
			border: 4px solid #ccc;
		}
		&:after{
			content: '';
			width: 10px;
			height: 10px;
			position: absolute;
			background: #ccc;
			left: -20px;
			top: 5px;
			border-radius: 50%;
			border: 3px solid #ccc;
		}
	}
}

.custom-header{
	margin-bottom: 15px;
}