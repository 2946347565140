.home-slider{
    h1{

        img{
            transform: translate(-5%,0%);
            -ms-transform: translate(-5%,28%);
            width: 2.2vw;
            position: relative;

        }
    }
    
    h3{
        font-family: 'font-cn-b' !important;
        letter-spacing: 3px !important;
        word-spacing: 4px !important;
    }

    p{
        font-family: 'font-r' !important;
    }

    p#slider-1-slide-1-layer-8 {
        font-family: 'font-cn-r' !important;
        letter-spacing: 2px !important;
    }

    p#slider-1-slide-1-layer-11{
        font-size: 15px !important;
    }
}

.custom-header{

    &.center{
        .wpb_wrapper{
            justify-content: center;
        }
    }
    .wpb_wrapper{
        display: flex;

        .header-front{
            margin-bottom: 0;
            font-size: 32px;
            font-family: 'font-cn-r';
            color: var(--color-font);
            letter-spacing: 8px;
            line-height: 42px;
        }
        .header-back{
            margin: 0;
            font-size: 32px;
            font-family: 'font-cn-b' !important;
            color: var(--color-blue) !important;
            letter-spacing: 8px;
            line-height: 42px;
            margin-left: 10px;
        }
    }
}

.custom-btn{
    position: relative;

    &.line{

        &:before{
            content: '';
            width: 250px;
            height: 1px;
            background: var(--color-blue);
            position: absolute;
            top: 50%;
            right: 0;
        }
    }

    &.v-line{
        padding-top: 40px;
        margin-top: 50px;
        &:before{
            content: '';
            width: 1px;
            height: 70px;
            background: #000064;
            position: absolute;
            top: 0;
            left: 50%;
        }
    }
    .vc_btn3.vc_btn3-color-grey.vc_btn3-style-outline{
        color: var(--color-blue);
        border: 1px solid var(--color-blue);
        font-family: 'font-cn-r';
        padding-bottom: 10px;
        font-size: 16px;
        background-color: white;

        &:hover{
            color: white;
            background-color: var(--color-blue);
        }
    }
}

.about-header{

    p{
        font-family: 'font-cn-r';
        margin-bottom: 0;
        color: white;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 5px;

        &.hohup{
            font-family: 'font-cn-b';
            font-size: 38px;

            img{
                position: relative;
                top: -5px;
                left: -3px;
            }
        }
    }

}

.about-sec{
    padding-top: 80px !important;
    .wpb_text_column{
        margin-bottom: 20px;
        color: white;

        a.link{
            font-weight: 700;
            color: white;
            text-decoration: underline;
        }
    }

    .service{
        p{
            margin-bottom: 0;
        }
        ul{
            padding-left: 20px;
            margin-top: 2px;
            a{
                font-family: 'font-b';
                color: white;
            }
        }
    }

    .about-icon-row{
        margin-top: 40px;
        .wpb_single_image{
            display: flex;
            flex-direction: column;

            img{
                width: 100px;
            }

            h2{
                order:2;
                color: white;
                text-align: center;
                font-family: 'font-m';
                font-size: 20px;
            }
        }
    }
}

.client-sec{
    padding-top: 80px !important;
    padding-bottom: 40px !important;

    .client-slider{
        margin-top: 40px;

        p{
            text-align: center;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img{
            max-width: 150px !important;
            max-height: 80px;
        }
    }
}

.contact-sec{
    padding-top: 80px !important;
    padding-bottom: 40px !important;

    .custom-header{
        margin-bottom: 15px;
    }

    p{
        text-align: center;
    }
}

.shadow-img.main-img{
    max-width: 500px;
    width: fit-content;
    
    position: relative;
    figure{
        position: relative;
        z-index: 9;
        img{
            height: 350px;
            object-fit: cover;
        }
    }
    .shadow-img.sub-img{
        max-width: 500px;
        position: absolute;
        bottom: -15px;
        right: -15px;
        filter: contrast(0.5) brightness(1.5);
        margin-bottom: 0;

        img{
            height: 350px;
            object-fit: cover;
        }
    }
}

.project-grid{
    .vc_gitem-zone-a .vc_gitem-row-position-bottom, .vc_gitem-zone-b .vc_gitem-row-position-bottom{
        height: 100%;
    }
    .vc_gitem_row .vc_gitem-col{
        transform: translateY(-50%);
        top: 32%;
        transition: all 0.3s ease-in-out;
    }
    .vc_custom_heading{
        h2{
            color: white;
            font-size: 18px;
            font-family: 'font-b';
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }
    
    p{
        color: white;
        font-size: 14px;
        line-height: 20px;
        font-family: 'font-r';
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        margin-bottom: 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .custom-btn {
        margin-bottom: 0;
        .vc_btn3-container{
            margin-bottom: 0;
        }
        a.vc_general.vc_btn3{
            border-radius: 0;
            background: transparent;
            color: white;
            border: 1px solid white;
            padding: 8px;
            font-size: 12px;
            font-family: 'font-cn-r';

            &:hover{
                background: var(--color-dark);

            }
        }
    }
    .vc_gitem-post-data{
        margin-bottom: 0;
    }
    .vc_grid-item-mini{
        .vc_gitem-animate-slideBottom{
            &:before{
                content: '';
                width: 100%;
                height: 70px;
                bottom: 0;
                position: absolute;
                background: rgba(0,0,0,0.4);
                z-index: 9;
                transition: all 0.9s ease-out;
            }
            

            .vc_gitem-zone-b{
                top: 200px;
            }
        } 
        &.vc_is-hover{
            .vc_gitem-animate-slideBottom:before {
                height: 100%;
                transition: all 0.6s ease-out;

                .vc_gitem-zone-b{
                    top: 0;
                }
            }

            .vc_col-sm-12.vc_gitem-col.vc_gitem-col-align-{
                transform: translateY(-50%);
                top: 50%;
            }

            p{
                opacity: 1;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}