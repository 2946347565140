.detail-sec{
    .contact-title{
        h2.vc_custom_heading {
            font-family: 'font-cn-b' !important;
            margin-bottom: 5px;
            color: var(--color-blue);
        }
        p{
            font-family: 'font-cn-b';
            font-size: 20px;
            color: var(--color-blue);
        }
    }

    .contact-detail{

        .vc_col-sm-4{
            .vc_column-inner>.wpb_wrapper{
                width: fit-content;
                margin: auto;
            }

            &:first-child{
                .vc_column-inner>.wpb_wrapper{
                    margin-left: 0;
                }
            }
        }
        .wpb_single_image{
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            
            h2{
                order: 2;
                font-size: 20px;
                font-family: 'font-b';
            }

            img{
                max-width: 80px;
            }
        }

        table{
			border: none;
			td{
				border: none;
				padding-top: 0;
				padding-bottom: 0;

				&:first-child{
					width: 10px;
					padding-right: 0;
					
				}
			}
        }
    }
    .social-icon{
        .footer-icon{
            display: flex;
            align-items: center;
            justify-content: center;

            i{
                font-size: 20px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--color-blue);
                color: white;
                border-radius: 50%;
                margin-bottom: 15px;
            }
            a{
                margin: 0 20px;
            }
        }
    }
}

.gmap{
    .wpb_gmaps_widget.wpb_content_element{
        margin-bottom: 0;
    }
}

.customer-logo .wpb_gallery{
    
    margin-bottom: 100px;
    ul{
        text-align: center;
        li.isotope-item{
            height: 70px;
            width: 20%;
            display: inline-block;
            align-items: center;
            justify-content: center;
            margin: 20px 0;
            position: static !important;
            float: none;
            padding: 5px;
            
            img{
                width: fit-content;
                display: inherit;
                max-width: 160px;
                max-height: 70px;
            }
        }
    }
}