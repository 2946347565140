.entry-header h1, .single .entry-header, .page .entry-header {
	display: none !important;
}
.entry-footer{ /*hide footer widget*/
	display: none;
}

#main .hentry{
	box-shadow: none;
	padding-top: 0;
}

div#content {
    margin: 0;
}

body{
	font-family: 'font-r' !important;
	font-size: 16px;
	line-height: 22px;
}

.page-id-57,.page-id-63,.single{
	#colophon>.container{
		display: none;
	}
}

section.vc_section.contact-sec{
	background-image: url(https://phh-groups.com/wp-content/uploads/2020/04/contact-bg.jpg) !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: cover !important; 
}