.service-sec{
    margin-top: 60px;
    margin-bottom: 60px;

    h3{
        font-family: 'font-b' !important;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    ul{
        padding-left: 20px;
    }

    .vc_separator {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    img.vc_single_image-img{
        margin-top: 10px;
    }
}

.career-title{
    h2{
        font-family: font-cn-b!important;
        margin-bottom: 5px;
        letter-spacing: 2px;
        color: #000064;
    }
}

.job {
    h3.vc_custom_heading{
        font-family: font-cn-r !important;
        font-size: 25px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    table{
        border: none;
        td{
            border: none;
            line-height: 22px;

            &:first-child{
                padding-left: 0;
                width: 130px;
            }
        }
    }
}