:root {
	--color-font: #4D4D4D;
	--color-blue: #000064;
	--color-red: #BD271F;
	--color-dark: #333333;
	--color-light: #E6E6E6;
}

@font-face{
	font-family: 'font-r';
	src: url('../fonts/SkolaSans-Regular.otf');
}

@font-face{
	font-family: 'font-m';
	src: url('../fonts/SkolaSans-Medium.otf');
}

@font-face{
	font-family: 'font-b';
	src: url('../fonts/SkolaSans-Bold.otf');
}

@font-face{
	font-family: 'font-cn-r';
	src: url('../fonts/SkolaSansCn-Regular.otf');
}

@font-face{
	font-family: 'font-cn-m';
	src: url('../fonts/SkolaSansCn-Medium.otf');
}

@font-face{
	font-family: 'font-cn-b';
	src: url('../fonts/SkolaSansCn-Bold.otf');
}

@font-face{
	font-family: 'font-cn-blk';
	src: url('../fonts/SkolaSansCn-Black.otf');
}